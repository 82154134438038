/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import {storyblokEditable, useStoryblokApi} from "@storyblok/react";
import React from "react";
import classNames from "classnames";
import ButtonGroup from "../partials/ButtonGroup";
import Image from "next/image";
import ContactFormTiny from "../functional/ContactFormTiny";
import {transformImage} from "../../utils/StoryblokService";
import {richTextResolver} from "@storyblok/richtext";

interface IProps {
    blok?: any
}

// bg-gradient-to-tl from-pink-100 to-rose-400
/**
 * bg-gradient-to-b from-transparent to-gray-900
 * bg-gradient-to-t from-warm-gray-50 via-gray-200 to-gray-300
 *
 * @param blok
 * @constructor
 */
export default function LandingPageHero({blok}: IProps) {
    const { render } = richTextResolver()

    const buttons = <ButtonGroup buttons={blok.buttons}/>

    return (
        <React.Fragment>
            <div
                className={
                    classNames({
                        '-mt-24 lg:-mt-32 pt-20 lg:pt-32': blok.negative_margin,
                    }, 'relative landing-page-hero')
                }
            >
                <div className={'absolute inset-0 z-10 w-full h-96 bg-gradient-to-b from-white to-transparent'}></div>

                {blok?.image &&
                    <Image
                        src={transformImage(blok.image.filename, "2560x1440")}
                        className={"absolute inset-0 z-0 w-full bg-gray-50 object-cover object-center opacity-10 lg:opacity-50"}
                        style={{filter: 'grayscale(0.5)'}}
                        alt={blok.image.alt}
                        fill={true}
                        loading={'lazy'}
                    />}

                <div className={classNames(blok.background_color, 'z-1 inset-0 w-full absolute hidden sm:block')}></div>

                <div
                    className={
                        classNames({
                            "max-w-screen-2xl overflow-clip px-4 sm:px-6 lg:px-8 mx-auto z-20 relative": true,
                            "mt-10": !blok.negative_margin
                        })
                    }
                >
                    <div
                        className={classNames("relative", blok.class, blok.container_padding || 'py-12 sm:py-24 lg:py-32')} {...storyblokEditable(blok)}>

                        <div className="relative ">

                            <main className="">
                                <div className="mx-auto ">
                                    <div
                                        className="flex flex-col justify-center sm:grid sm:grid-cols-12 gap-8 lg:gap-12 items-center">
                                        <div
                                            className="text-center sm:text-left sm:mx-auto lg:mx-0 sm:col-span-6 lg:flex lg:items-center lg:text-left">
                                            <div>

                                                <p className={"mt-4 mb-3 sm:mb-0 md:mt-0 text-sm lg:text-lg font-semibold " + (blok.headline_color || 'text-primary')}>
                                                    {blok.subtitle}
                                                </p>

                                                {/*<DynamicHeading fallback={'h1'} heading={blok.headline}*/}
                                                {/*                className={"-mt-1 md:mt-4 text-4xl font-bold tracking-tight sm:text-5xl md:text-6xl landing-headline " + (blok.headline_color || 'text-primary')}>*/}

                                                <div className={'landing-headline text-white'}>
                                                    <div dangerouslySetInnerHTML={{
                                                        __html: render(blok.headline) as string
                                                    }}></div>
                                                </div>

                                                <div
                                                    className={"intro-text px-8 sm:px-0 mt-3 text-base sm:mt-5 text-xl lg:text-lg xl:text-xl rich-text " + (blok.intro_color || 'text-gray-500')}
                                                    dangerouslySetInnerHTML={{
                                                        __html: render(blok.intro) as string
                                                    }}
                                                >
                                                </div>

                                                <div className={'grid grid-cols-1 sm:grid-cols-2 gap-4'}>
                                                    {buttons}
                                                </div>

                                                <div
                                                    className={'flex gap-2 lg:gap-8 text-white mt-6 justify-center lg:justify-start items-start stats'}>
                                                    <div className={'text-center space-y-2'}>
                                                        <p className={'font-bold text-base sm:text-2xl xl:text-4xl'}>
                                                            90+
                                                        </p>
                                                        <hr className={'border-2 border-secondary lg:border-white'}/>
                                                        <p className={'text-sm sm:text-base uppercase'}>
                                                            Clients Helped
                                                        </p>
                                                    </div>
                                                    <div className={'text-center space-y-2'}>
                                                        <p className={'font-bold text-base sm:text-2xl xl:text-4xl'}>
                                                            15+
                                                        </p>
                                                        <hr className={'border-2 border-secondary lg:border-white'}/>
                                                        <p className={'text-sm sm:text-base uppercase'}>
                                                            Years Experience
                                                        </p>
                                                    </div>
                                                    <div className={'text-center space-y-2'}>
                                                        <p className={'font-bold text-base sm:text-2xl xl:text-4xl'}>
                                                            5.0 <span className={'hidden lg:inline'}>Stars</span>
                                                        </p>
                                                        <hr className={'border-2 border-secondary lg:border-white'}/>
                                                        <p className={'text-sm sm:text-base uppercase'}>
                                                            On Google
                                                        </p>
                                                    </div>
                                                    <div className={'text-center space-y-2'}>
                                                        <p className={'font-bold text-base sm:text-2xl xl:text-4xl'}>
                                                            CAN DO
                                                        </p>
                                                        <hr className={'border-2 border-secondary lg:border-white'}/>
                                                        <p className={'text-sm sm:text-base uppercase'}>
                                                            Attitude
                                                            <span className={'sm:hidden'}>
                                                            <br/>
                                                                &nbsp;
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div
                                            className={
                                                classNames("flex", {
                                                    "items-center": true,
                                                    "sm:col-span-6 sm:col-start-7 ": true,
                                                })
                                            }>
                                            <div
                                                className="sm:mx-auto lg:mr-0 sm:w-full sm:overflow-hidden sm:rounded-lg">
                                                <ContactFormTiny blok={blok}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
